import React, { useState } from "react";
import {
	Space,
	Skeleton,
	Drawer,
	Button,
	message,
	Table,
	Tag,
	Modal,
	Switch,
	Tooltip,
	Typography
} from "antd";
import { 
	get_recurrencies, 
	create_recurrency,
	edit_recurrency,
	toggle_recurrency,
} from "../../services/recurrencies";
import { edit_invoice } from "../../services/invoices";
import RecurrencyTags from "./RecurrencyTags";
import { AccountingClassificationSelect, AnalyticClassificationSelect } from "./auxiliarComponents/SelectInputs";
import PropTypes from "prop-types";

import "../../hover.css";

const no_created_recurrency = {
	default: false,
	key_words: [],
	accounting_classification: "",
	analytic_classification: "",
};

const Classification_Recurrencies = ({
	token,
	company,
	position
}) => {

	const [loadingRecurrencies, setLoadingRecurrencies] = useState(false);
	const [loadingReClassify, setLoadingReClassify] = useState(false);
	const [loadingToggle, setLoadingToggle] = useState(false);
	const [openCreateRecurrency, setOpenCreateRecurrency] = useState(false);
	const [openRecurrencies, setOpenRecurrencies] = useState(false);

	const [recurrencies, setRecurrencies] = useState([]);
	const [recurrencyToCreate, setRecurrencyToCreate] = useState(no_created_recurrency);

	const [tags, setTags] = useState([]);	

	const [selectedRecurrency, setSelectedRecurrency] = useState({});
	const [openEditRecurrency, setOpenEditRecurrency] = useState(false);
	const [loadingRecurrency, setLoadingRecurrency] = useState(false);

	function arraysAreEqual(arr1, arr2) {
		const sortedArr1 = arr1.slice().sort();
		const sortedArr2 = arr2.slice().sort();
	
		if (sortedArr1.length !== sortedArr2.length) {
			return false;
		}
	
		for (let i = 0; i < sortedArr1.length; i++) {
			if (sortedArr1[i] !== sortedArr2[i]) {
				return false;
			}
		}
	
		return true;
	}
	
	const handleCreateRecurrency = async () => {
		let rec = recurrencyToCreate;
		rec.key_words = tags;
		rec.accounting_classification = `${rec.accounting_classification}`;
		rec.analytic_classification = `${rec.analytic_classification}`;

		if(rec.key_words.length === 0){
			message.error("Error: Ingresa al menos una palabra clave");
			return;
		}
		
		const sameTags = recurrencies.find(
			recurrency => arraysAreEqual(recurrency.specs.key_words, rec.key_words)
		);

		if(sameTags){
			message.error("Error: Mismos tags de otra recurrencia, porfavor crea tags diferentes");
			return;
		}

		setOpenCreateRecurrency(false);
		setLoadingRecurrencies(true);
		
		if (company["id"] != "") {
			try {
				const res = await create_recurrency(token, "invoice_classification", company.id, rec, position);
				if (res) {
					handleRecurrenciesModal();
				}
			} catch (error) {
				setLoadingRecurrencies(false);
			}
		}
	};

	const editRecurrency = async () => {
		if(selectedRecurrency.specs.key_words.length === 0){
			message.error("Error: Ingresa al menos una palabra clave");
			return;
		}

		const sameTags = recurrencies.find(
			recurrency => arraysAreEqual(recurrency.specs.key_words, selectedRecurrency.specs.key_words)
		);

		if(sameTags){
			message.error("Error: Mismos tags de otra recurrencia, porfavor crea tags diferentes");
			return;
		}

		try{
			setLoadingRecurrency(true);
			const res = await edit_recurrency(token, selectedRecurrency.id, "invoice_classification", selectedRecurrency.specs);
			if(res){
				message.success("Specificaciones de la recurrencia editada con éxito");
				setRecurrencies(recurrencies.map(recurrency => {
					if(recurrency.id === selectedRecurrency.id){
						return selectedRecurrency;
					}
					return recurrency;
				}));
				setLoadingRecurrency(false);
				setOpenEditRecurrency(false);
			}
		} catch (error){
			const error_message = error.response?.data ? error.response?.data : "";
			message.error("Ocurrió un error cambiando las especificaciones de la recurrencia. " + error_message);
			setLoadingRecurrency(false);
		}
	};

	const toggleRecurrency = async (item) => {
		if (company["id"] != "") {
			try {
				setLoadingToggle(true);
				const res = await toggle_recurrency(token, item.id);
				if (res) {
					message.success("Ha cambiado el estado de activación de la recurrencia con éxito");
					handleRecurrenciesModal();
				}
			} catch (error) {
				setLoadingToggle(false);
				message.error("Ocurrió un error cambiando el estado de la activación de la recurrencia");
			}
		}
	};

    
	const handleRecurrenciesModal = async () => {
        
		if (company["id"] != "") {
			setOpenRecurrencies(true);
			setLoadingRecurrencies(true);
			try {
				const res = await get_recurrencies(token, "invoice_classification", company["id"], position);
				if (res) {
					if (res.length == 0) {
						message.info("No hay recurrencias");
					}
					setRecurrencies(res);
					setLoadingRecurrencies(false);
					setLoadingToggle(false);
				}
			} catch (error) {
				message.error(error.message);
				setLoadingRecurrencies(false);
			}
		}
		else {
			message.error("Debe seleccionar una compañía");
		}


	};

	const onCloseRecurrencies = () => {
		setOpenRecurrencies(false);
		setRecurrencyToCreate(no_created_recurrency);
		setRecurrencies([]);
	};

	const showEditRecurrency = (recurrency_id) => {
		setOpenEditRecurrency(true);
		setSelectedRecurrency(
			recurrencies.filter(recurrency => recurrency.id === recurrency_id.id)[0]
		);
	};

	function findAccountNameById(accountingPlan, accountId) {
		if(accountingPlan === undefined) return null;
		
		// Buscar el elemento en accountingPlan que tenga un id que coincida con accountId
		const matchingElement = accountingPlan.find(planItem => planItem.id.toString() === accountId.toString());
      
		// Si se encuentra un elemento coincidente, retorna su propiedad 'name'
		if (matchingElement) {
			if (!matchingElement.display_name) {
				return matchingElement.name;
			}
			return matchingElement.display_name;
		} else {
			// Si no se encuentra ninguna coincidencia, retorna null o un mensaje indicativo
			return null; // o "No matching account found"
		}
	}

	function chooseRandomColor() {
		// Definir un arreglo de colores posibles
		const colores = ["magenta", "volcano", "orange", "gold", "green", "cyan", "blue", "geekblue", "purple"];
      
		// Elegir un índice aleatorio basado en la longitud del arreglo de colores
		const indiceAleatorio = Math.floor(Math.random() * colores.length);
      
		return colores[indiceAleatorio];
	}

	const handleReClassifyInvoices = async () => {
		
		if (company["id"] != "") {
			setLoadingReClassify(true);
			try {
				const res = await edit_invoice(token, "reclassify", {company_tid: company["tid"]}, position);
				if (res) {	
					message.success("Facturas Reclasificadas");
					message.info("Para ver los cambios debes buscar las facturas de nuevo", 5);
					setLoadingReClassify(false);
				}
			} catch (error) {
				message.error(error.response.data);
				setLoadingReClassify(false);
			}
		}
		else {
			message.error("Debe seleccionar una compañía");
		}
	};

	const updateRecurrencySpecs = (property, value) => {
		setSelectedRecurrency(selected => ({
			...selected,
			specs: {
				...selected.specs,
				[property]:value,
			}
		}));
	};

	const setTagsForSelectedRecurrency = (newTags) => {
		updateRecurrencySpecs("key_words", newTags);
	};

	const updateAccountingClassificationForSelectedRecurrency = (element) => {
		updateRecurrencySpecs("accounting_classification", element);
	};

	const updateAnalyticClassificationClassificationForSelectedRecurrency = (element) => {
		updateRecurrencySpecs("analytic_classification", element);
	};

	const updateAccountingClassificationForCreateRecurrency = (element) => {
		setRecurrencyToCreate({...recurrencyToCreate, accounting_classification: element});
	};

	const updateAnalyticClassificationForCreateRecurrency = (element) => {
		setRecurrencyToCreate({...recurrencyToCreate, analytic_classification: element});
	};
    
	return (<>
		<Button
			style={{
				marginLeft: "10px",
			}}
			loading={loadingRecurrencies}
			onClick={() => {
				handleRecurrenciesModal();
			}}
		>
				Recurrencias
		</Button>
		{
			company.id != "" &&
			<>
				<Drawer 
					title="Recurrencias" 
					onClose={onCloseRecurrencies} 
					open={openRecurrencies}
					width="80%"
					extra={
						<>
							<Button
								style={{
									marginLeft: "10px",
								}}
								loading={loadingReClassify}
								onClick={handleReClassifyInvoices}>
								Re-Clasificar Facturas
							</Button>
							<Button
								type="primary"
								style={{
									marginLeft: "10px",
								}}
								loading={loadingRecurrencies}
								onClick={() => {setOpenCreateRecurrency(true);}}>
								Crear Recurrencia
							</Button>
						</>
					}
				>
					{
						recurrencies.length == 0 &&
                            <   Skeleton active={loadingRecurrencies} />
					}
					{
						recurrencies.length !== 0 &&
							<Table
								pagination={{
									position: "top",
									align: "end",
									disabled: false,
									showSizeChanger: false,
									pageSize: 8,
								}}
								columns={[
									{
										title: "Palabras Clave",
										dataIndex: "key_words",
										key: "key_words",
										render: (tags) => (
											<>
												{tags.map((tag) => {
													const color = chooseRandomColor();
													const is_large = tag.length > 20;
													if (is_large)
														return (
															<Tooltip title={tag} key={tag}>
																<Tag color={color} key={tag}>
																	{is_large ? `${tag.slice(0, 20)}...` : tag}
																</Tag>
															</Tooltip>
														);
													else
														return (
															<Tag color={color} key={tag}>
																{tag}
															</Tag>
														);
												})}
											</>
										),
										defaultSortOrder: "descend",
										sorter: (a, b) => a.key_words.length - b.key_words.length,
									},
									{
										title: "Activo",
										dataIndex: "active",
										key: "active",
										render: (active, item) => (
											<Switch loading={loadingToggle} checked={active} onChange={() => toggleRecurrency(item)} />
										),
										sorter: (a, b) => a.active - b.active,
									},
									{
										title: "Por defecto",
										dataIndex: "default",
										key: "default",
										render: (text) => (
											text ? 
												<Tag color={"Green"} key={1}>
                                        Por Defecto
												</Tag> : 
												<Tag color={"gray"} key={1}>
                                        X
												</Tag>
										),
									},
									{
										title: "Cuenta Contable",
										dataIndex: "accounting_classification",
										key: "accounting_classification",
										render: (text) => (
											<p>{text}</p>
										),
									},
									{
										title: "Cuenta Analítica",
										dataIndex: "analytic_classification",
										key: "analytic_classification",
										render: (text) => (
											text ? <p>{text}</p> : <p>Sin Clasificación</p>
										),
									},
									{
										title: "Operación",
										dataIndex: "Operación",
										render: (_, record) => {											
											return <Typography.Link onClick={() => showEditRecurrency(record)}>
												Editar
											</Typography.Link>;
										}
									}
								]} 
								dataSource={recurrencies.map((item, index) => ({
									key: index,
									active: item.active,
									default: item.specs.default,
									key_words: item.specs.key_words,
									accounting_classification: findAccountNameById(company.accounting_resources?.accounting_plan,item.specs.accounting_classification),
									analytic_classification: findAccountNameById(company.accounting_resources?.others?.analytic_accounts,item.specs.analytic_classification),
									id: item.id
								}))}
							/>
					}
					<Modal
						centered
						open={openCreateRecurrency}
						onOk={() => handleCreateRecurrency()}
						onCancel={() => setOpenCreateRecurrency(false)}
					>
						<h3>Clasificación por defecto:</h3>
						<Switch onChange={() => {setRecurrencyToCreate({...recurrencyToCreate, default: !recurrencyToCreate.default});}} />
						<h3>Palabras Clave:</h3>
						<RecurrencyTags tags={tags} setTags={setTags}/>
						<p>Cuenta Contable</p>
						<AccountingClassificationSelect 
							accounting_classification_value={recurrencyToCreate.accounting_classification} 
							updateElement={updateAccountingClassificationForCreateRecurrency}
							company={company}
							position={position}
						/>
						<p>Cuenta Analítica</p>
						<AnalyticClassificationSelect
							analytic_classification_value={recurrencyToCreate.analytic_classification}
							updateElement={updateAnalyticClassificationForCreateRecurrency}
							company={company}
						/>
					</Modal>
				</Drawer>
				<Drawer
					title="Editar Recurrencia"
					placement="right"
					width={800}
					onClose={() => setOpenEditRecurrency(false)}
					open={openEditRecurrency}
					extra={
						<Space>
							<Button type="primary" loading={loadingRecurrency} onClick={editRecurrency}>
								Editar
							</Button>
						</Space>
					}
				>
					<div>
						<h3>Por Defecto: </h3>
						<Switch checked={selectedRecurrency?.specs?.default} onChange={(e) => updateRecurrencySpecs("default", e)} />
					</div>
					<div>
						<h3>Palabras Clave: </h3>
						<RecurrencyTags tags={selectedRecurrency?.specs?.key_words} setTags={setTagsForSelectedRecurrency}/>
					</div>
					<div>
						<h3>Cuenta Contable: </h3>
						<AccountingClassificationSelect 
							accounting_classification_value={selectedRecurrency?.specs?.accounting_classification} 
							updateElement={updateAccountingClassificationForSelectedRecurrency}
							company={company}
							position={position}
						/>
					</div>
					<div>
						<h3>Cuenta Analítica: </h3>
						<AnalyticClassificationSelect
							analytic_classification_value={selectedRecurrency?.specs?.analytic_classification}
							updateElement={updateAnalyticClassificationClassificationForSelectedRecurrency}
							company={company}
						/>
					</div>
				</Drawer>
			</>
		}
	</>);
};

Classification_Recurrencies.propTypes = {
	token: PropTypes.string.isRequired,
	company: PropTypes.shape({
		id: PropTypes.string,
		tid: PropTypes.string,
		accounting_resources: PropTypes.shape({
			accounting_plan: PropTypes.any,
			expense_accounts: PropTypes.any,
			sale_accounts: PropTypes.any,
			others: PropTypes.shape({
				analytic_accounts: PropTypes.any
			})
		})
	}),
	position: PropTypes.string,
};

export default Classification_Recurrencies;